<template>
  <div class="mt-10 pa-5" style="background: #fbfbfb;border: 1px solid #dadbca">
    <div class="green--text text--darken-2 font-weight-bold mb-2 text-h6">
      {{ title }}
    </div>

    <template v-if="content || sections">
      <div
        v-for="item in content ? content : sections"
        :key="item.id"
        class="my-2 d-flex align-center"
      >
        <v-icon v-if="icon" style="fontSize:20px">{{ icon }}</v-icon>
        <div v-if="title" class="body-2 mx-2">
          {{
            content
              ? item.feature_id[lang + 'title']
              : sections
              ? item[lang + 'title']
              : null
          }}
        </div>
      </div>
    </template>
    <div v-if="text" class="body-2" style="lineHeight:1.8" v-text="text" />
  </div>
</template>

<script>
export default {
  props: ['title', 'content', 'icon', 'text', 'sections'],
  data() {
    return {
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
};
</script>

<style></style>
